import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
  },
  {
    path: '',
    loadChildren: () => import('./login-module/login-module.module').then( m => m.LoginModuleModule)
  },
  // {
  //   path: 'otp',
  //   loadChildren: () => import('./otp-module/otp-module.module').then( m => m.OtpModuleModule)
  // },
  {
    path: 'user',
    loadChildren: () => import('./user-module/user-module.module').then( m => m.UserModuleModule)
  },

  {
    path: 'signup',
    loadChildren: () => import('./signup-module/signup-module.module').then( m => m.SignupModuleModule)
  },
 
 
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'todays-birthday',
    loadChildren: () => import('./todays-birthday/todays-birthday.module').then( m => m.TodaysBirthdayPageModule)
  },
  
  
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  
  {
    path: 'mobile-dashboard',
    loadChildren: () => import('./mobile-dashboard/mobile-dashboard.module').then( m => m.MobileDashboardPageModule)
  },
 
  {
    path: 'today-anniversary',
    loadChildren: () => import('./today-anniversary/today-anniversary.module').then( m => m.TodayAnniversaryPageModule)
  },
  
  
  {
    path: 'contact',
    loadChildren: () => import('./contact-module/contact-module.module').then( m => m.ContactModuleModule),
    //canActivate:[AuthGuard]
  },
 
  {
    path: 'setting',
    loadChildren: () => import('./setting-module/setting-module.module').then( m => m.SettingModuleModule)
  },
  {
    path: 'password',
    loadChildren: () => import('./password-module/password-module.module').then( m => m.PasswordModuleModule)
  },

  {
    path: 'sms-report',
    loadChildren: () => import('./sms-report/sms-report.module').then( m => m.SmsReportPageModule)
  },
  {
    path: 'sms-campaign-report',
    loadChildren: () => import('./sms-campaign-report/sms-campaign-report.module').then( m => m.SmsCampaignReportPageModule)
  },
  {
    path: 'sms-delivery-report',
    loadChildren: () => import('./sms-delivery-report/sms-delivery-report.module').then( m => m.SmsDeliveryReportPageModule)
  },
  {
    path: 'sms-schedule-report',
    loadChildren: () => import('./sms-schedule-report/sms-schedule-report.module').then( m => m.SmsScheduleReportPageModule)
  },
  {
    path: 'sms-archieve-report',
    loadChildren: () => import('./sms-archieve-report/sms-archieve-report.module').then( m => m.SmsArchieveReportPageModule)
  },
  {
    path: 'sms-credit-history',
    loadChildren: () => import('./sms-credit-history/sms-credit-history.module').then( m => m.SmsCreditHistoryPageModule)
  },
 
  {
    path: 'pin',
    loadChildren: () => import('./pin-module/pin-module.module').then( m => m.PinModuleModule)
  },
  {
    path: 'otp',
    loadChildren: () => import('./otp-module/otp-module.module').then( m => m.OtpModuleModule)
  },
  {
    path: 'whatsapp',
    loadChildren: () => import('./whatsapp-module/whatsapp-module.module').then( m => m.WhatsappModuleModule)
  },
  {
    path: 'sms',
    loadChildren: () => import('./sms-module/sms-module.module').then( m => m.SmsModuleModule)
  },
 
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
